import dayjs from 'dayjs';
import { useSaveCoupon } from 'hooks/Mutate/StoreDetail';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { PRICE_TYPE_DEFAULT } from 'script/const/service-consts';
import { isStringEmpty, select } from 'script/util/common-utils';
import { getPriceUnitName } from 'script/util/service-utils';
import styled from 'styled-components';
import { moneyFormatter } from 'utils';

import { ToastContext } from '../../context/toast';
import { Modal } from '../common';
import LoginContext, { View } from '../common/login/_context';

const CouponModalNew = ({
  globalSearch,
  onClose,
  session,
  state,
  storeCode,
}) => {
  const {
    discountPrice,
    discountRate,
    discountType,
    id,
    isDownload,
    issueEndDate,
    issueStartDate,
    item,
    marketName,
    permalink,
    product,
    storeName,
    type,
  } = state.data || {};

  const { showToast } = useContext(ToastContext);
  const { action } = useContext(LoginContext);
  const history = useHistory();
  const { mutateAsync } = useSaveCoupon(storeCode, id);
  const [selectedWeightPriceIndex, setSelectedWeightPriceIndex] = useState(0);

  const onClickSaveCoupon = () => {
    if (!isLogin) {
      if (globalSearch) {
        sessionStorage.setItem(
          'preLoginState',
          JSON.stringify({ couponId: id, searchKeyword: globalSearch })
        );
      } else {
        sessionStorage.setItem(
          'preLoginState',
          JSON.stringify({ couponId: id })
        );
      }

      action.open(View.CHANNEL);
      return;
    }
    mutateAsync(id)
      .then((res) => {
        if (res.message === 'data inserted') {
          showToast('쿠폰 발급이 완료되었습니다.');
        } else {
          showToast('이미 보유 중인 쿠폰입니다.');
        }
      })
      .catch((err) => {
        // console.log(err);
        showToast('쿠폰을 불러오는데 실패했습니다.');
        onClose();
      });
  };

  // 비로그인으로 쿠폰받기 접근 후 돌아왔을때 쿠폰 받아지도록
  useEffect(() => {
    const preLoginState = JSON.parse(sessionStorage.getItem('preLoginState'));
    const preLoginStateValue = preLoginState && Object.values(preLoginState)[0];

    if (preLoginState && preLoginStateValue === id) {
      onClickSaveCoupon();
    }
  }, [id]);

  const isLogin = session && session.identity !== null;
  const isGroupType =
    product?.priceSettingType === 'GROUP' ||
    product?.priceSettingType === 'QUOTE';
  const isPercentageType = discountType === 'PERCENTAGE';

  const onClickStoreDetail = () => {
    if (type === 'menu') {
      history.push(`/menu/${permalink}/${product.id}`);
    } else {
      history.push(`/${permalink}?price=${item.baseId}`);
    }
  };

  return (
    <Modal state={state} title="할인쿠폰" onClose={onClose}>
      <Container>
        <MenuLocationWrapper>
          <MenuName>{type === 'menu' ? product?.name : item?.name}</MenuName>
          <MenuName>
            {isPercentageType
              ? `${discountRate}% 할인`
              : `${moneyFormatter(discountPrice)}원 할인`}
          </MenuName>
          <LocationLabel>
            {marketName} {storeName}
          </LocationLabel>
        </MenuLocationWrapper>

        <GradientBox id="gradient-box" isMobile={isMobile} />

        <ContentsScrollBox>
          <Image
            src={type === 'menu' ? product?.imageUrl : item?.imageUrl}
            style={{ objectFit: 'contain' }}
          />

          {type === 'menu' ? (
            <ProductMenuInfoBox>
              {isGroupType ? (
                <WeightPricesBox>
                  <TabBox>
                    {product?.weightPrices.map((weightPrice, index) => (
                      <Tab
                        key={`weightPrice-tab-${weightPrice.id}`}
                        isActive={index === selectedWeightPriceIndex}
                        onClick={() => setSelectedWeightPriceIndex(index)}
                      >
                        {weightPrice.name}
                      </Tab>
                    ))}
                  </TabBox>

                  {product?.weightPrices[selectedWeightPriceIndex].prices.map(
                    (item) => {
                      return (
                        <MenuInfoContainer key={`coupon_info-${item.id}`}>
                          <MenuSizeBox>
                            <MenuBar />
                            <MenuSizeName>{item.name}</MenuSizeName>
                            <MenuSize>{item.description}</MenuSize>
                          </MenuSizeBox>
                          <MenuSizeBox>
                            <OriginalPrice>
                              {moneyFormatter(item.price)}원
                            </OriginalPrice>
                            <DiscountedPrice>
                              {isPercentageType
                                ? moneyFormatter(
                                    Math.round(
                                      (item.price -
                                        item.price * (discountRate / 100)) /
                                        1000
                                    ) * 1000
                                  )
                                : moneyFormatter(item.price - discountPrice)}
                              원
                            </DiscountedPrice>
                          </MenuSizeBox>
                        </MenuInfoContainer>
                      );
                    }
                  )}
                </WeightPricesBox>
              ) : (
                product?.prices.map((item) => (
                  <MenuInfoContainer key={`coupon_info${item.id}`}>
                    <MenuSizeBox>
                      <MenuBar />
                      <MenuSizeName>{item.name}</MenuSizeName>
                      {select(
                        !isStringEmpty(item.description),
                        <MenuSize>{item.description}</MenuSize>
                      )}
                      {select(
                        item?.unitValue > 0 &&
                          item?.unit !== PRICE_TYPE_DEFAULT,
                        <MenuUnit>
                          {`${moneyFormatter(item.unitValue)}${getPriceUnitName(item.unit)}`}
                        </MenuUnit>
                      )}
                    </MenuSizeBox>
                    <MenuSizeBox>
                      <OriginalPrice>
                        {moneyFormatter(item.price)}원
                      </OriginalPrice>
                      <DiscountedPrice>
                        {isPercentageType
                          ? moneyFormatter(
                              Math.round(
                                (item.price -
                                  item.price * (discountRate / 100)) /
                                  1000
                              ) * 1000
                            )
                          : moneyFormatter(item.price - discountPrice)}
                        원
                      </DiscountedPrice>
                    </MenuSizeBox>
                  </MenuInfoContainer>
                ))
              )}
            </ProductMenuInfoBox>
          ) : (
            <ProductMenuInfoBox>
              <MenuInfoContainer>
                <MenuSizeBox>
                  <MenuBar />
                  {select(
                    item?.unitValue > 0,
                    <MenuSizeName>{getPriceUnitName(item?.unit)}</MenuSizeName>
                  )}
                  <MenuSize>{item?.description}</MenuSize>
                </MenuSizeBox>
                <MenuSizeBox>
                  <DiscountedPrice>
                    {isPercentageType
                      ? `${discountRate}% 할인`
                      : `${moneyFormatter(discountPrice)}원 할인`}
                  </DiscountedPrice>
                </MenuSizeBox>
              </MenuInfoContainer>
            </ProductMenuInfoBox>
          )}

          <DateBox>
            {issueStartDate && issueEndDate
              ? `${dayjs(new Date(issueStartDate)).format(
                  'YY.MM.DD(ddd)'
                )} ~ ${dayjs(new Date(issueEndDate)).format(
                  'YY.MM.DD(ddd)'
                )}까지`
              : ''}{' '}
            사용 가능합니다.
            <br />
            쿠폰을 받은 후 가게 방문 시 보여주세요.
          </DateBox>
        </ContentsScrollBox>

        <ButtonFooter isDownload={isDownload}>
          <Button onClick={onClickStoreDetail}>메뉴상세보기</Button>
          <Button disabled={isDownload} onClick={() => onClickSaveCoupon()}>
            {isDownload ? `받기 완료` : `쿠폰 받기`}
          </Button>
        </ButtonFooter>
      </Container>
    </Modal>
  );
};

const Image = styled.img``;

const Tab = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
  padding: 8px 12px;
  background-color: ${({ isActive }) =>
    isActive
      ? `var(--Blue-blue-2, #ecf6ff)`
      : `var(--new-gray-gray-1, #F7F8F9)`};
  text-align: center;
  border-radius: 150px;
  cursor: pointer;
  color: ${({ isActive }) =>
    isActive ? `var(--Blue-blue-9, #2f80ed)` : `var(--Gray-gray-6, #8A8D90)`};
`;

const TabBox = styled.div`
  display: flex;
  gap: 4px;
`;

const Button = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonFooter = styled.div`
  display: flex;
  height: 56px;
  overflow: hidden;

  button {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
  }

  button:first-child {
    background-color: var(--new-gray-gray-1, #f7f8f9);
    color: var(--new-blue-blue-9, #2f80ed);
    border-radius: 0 0 0 5px;
  }

  button:nth-child(2) {
    background: ${({ isDownload }) =>
      isDownload ? `#97BFF6` : `var(--new-blue-blue-9, #2f80ed)`};
    color: var(--new-black-white-white, #fff);
    border-radius: 0 0 5px 0;
  }
`;

const DateBox = styled.div`
  color: var(--new-gray-gray-6, #8a8d90);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

const DiscountedPrice = styled.div`
  color: var(--new-gray-gray-11, #26282b);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.3px;
`;

const OriginalPrice = styled.div`
  color: var(--new-gray-gray-6, #8a8d90);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-decoration: line-through;
`;

const MenuSize = styled.div`
  color: var(--new-gray-gray-11, #26282b);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.3px;
`;

const MenuUnit = styled.div`
  color: var(--new-gray-gray-11, #26282b);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

const MenuSizeName = styled.div`
  overflow: hidden;
  color: var(--new-gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const MenuBar = styled.div`
  border-radius: 5px;
  width: 3px;
  height: 14px;
  background-color: var(--new-gray-gray-2, #e8ebed);
`;

const MenuSizeBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const WeightPricesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MenuInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductMenuInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const GradientBox = styled.div`
  position: absolute;
  top: 150px;
  width: ${({ isMobile }) => (isMobile ? '345px' : '390px')};
  height: 18px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
`;

const ContentsScrollBox = styled.div`
  max-height: 368px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 16px 23px 16px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const LocationLabel = styled.div`
  color: var(--new-gray-gray-6, #8a8d90);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

const MenuName = styled.div`
  color: var(--new-blue-blue-9, #2f80ed);
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.3px;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
`;

const MenuLocationWrapper = styled.div`
  padding: 0 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  display: relative;
  width: 100%;
  max-width: 390px;
`;

export default CouponModalNew;
