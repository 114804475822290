import styled from 'styled-components';

const DialogContainerWrap = ({ children }) => {
  return <DialogContainerCss>{children}</DialogContainerCss>;
};

export default DialogContainerWrap;

const DialogContainerCss = styled.div`
  width: 100%;
  height: 100%;
  /* 임시 z-index layer 층 확인하고 수정할것 */
  z-index: 10000;
  position: fixed;
  top: 50%;
  right: -50%;
  transform: translate(-50%, -50%);
`;
