import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

const targetPaths = ['/menu', '/마이페이지', '/search', '/쿠폰'];

const useShowNavigation = () => {
  const location = useLocation();

  const shouldHideNavigation = useMemo(() => {
    return targetPaths.some((routePath) =>
      matchPath(location.pathname, {
        exact: false,
        path: routePath,
        strict: false,
      })
    );
  }, [location.pathname]);

  return !shouldHideNavigation;
};

export default useShowNavigation;
