import client from 'api/client';
import axios from 'axios';

const { CancelToken } = axios;
let cancel;

export const create_order = (params) => client.post('/www/quick/order', params);

export const get_delivery_charge = (params, path = 'quick') => {
  if (cancel) {
    cancel();
  }

  return client.get(`/www/quick/ship/${path}`, {
    cancelToken: new CancelToken((c) => (cancel = c)),
    params,
  });
};

export const cancel_order = (orderCode, orderType) =>
  orderType === 3
    ? client.post(`/www/orders/day-delivery/${orderCode}/cancel`)
    : client.put(`/www/quick/cancel/${orderCode}`);

/**
 * @param {'package'|'quick'} type
 * @param {object} options
 * @param {string?} options.address
 * @param {string?} options.permalink
 * @param {string} options.storeCode
 * @returns {Promise<object>}
 */
export const reservation_time = (type, { address, permalink, storeCode }) => {
  if (!(type === 'package' || type === 'quick')) {
    throw new Error('Invalid type');
  }

  return client.get(`/www/quick/reservationTime/${type}`, {
    params: {
      address,
      permalink,
      storeCode,
    },
  });
};

export const order_available = (storeCode, orderType) =>
  client.get(
    `/www/quick/orderAvailable/all?storeCode=${storeCode}&orderType=${orderType}`
  );

export const validateOrder = (params) =>
  client.post('/www/quick/validateOrder', params);

export const checkDelivery = (address) =>
  client.get(`/www/quick/deliveryCheck`, {
    params: {
      address,
    },
  });

export const orderSameDayDelivery = (data) =>
  client.post('/www/orders/day-delivery', data);

export const confirmPurcahse = (orderId) =>
  client.put(`/www/orders/convenience-store/${orderId}/confirm`);

//= =====================================================

export const cancelDayDeliveryOrder = (orderCode) =>
  client
    .post(`/www/orders/day-delivery/${orderCode}/cancel`)
    .then((res) => res.data);

export const cancelOrder = (orderCode) =>
  client.put(`/www/quick/cancel/${orderCode}`).then((res) => res.data);

// 메뉴와 가게가 올바르게 페어 된 것인지 확인
export const checkValidationMenuItems = ({ menuCodes, storeCode }) =>
  client
    .post(`/www/store/${storeCode}/validate`, {
      menuCodes,
    })
    .then((res) => res.data);

// 가게 전화번호 조회
export const getStorePhone = (storeCode) =>
  client.get(`/www/stores/${storeCode}/phone`).then((res) => res.data);

// 주문 취소
export const cancelOrderByOrderType = ({ orderCode, orderType }) => {
  switch (orderType) {
    case 'DAY_DELIVERY':
      return client
        .put(`/www/order/day-delivery/${orderCode}/cancel`)
        .then((res) => res.data);
    // case 'CONVENIENCE_STORE':
    case 'PACKAGE':
    case 'QUICK':
      return client
        .put(`/www/order/${orderCode}/cancel`)
        .then((res) => res.data);
  }
};
