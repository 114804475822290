/* eslint-disable no-useless-escape */
const ORDER_TYPE = {
  // convenience: '편의점픽업',
  dayDelivery: '당일배송',
  package: '포장',
  quick: '퀵배달',
};

const ORDER_TYPE_ALL_ARR = [
  {
    label: '포장',
    value: 'package',
  },
  {
    label: '퀵배달',
    value: 'quick',
  },
  {
    label: '당일배송',
    value: 'dayDelivery',
  },
  // {
  //   label: '편의점픽업',
  //   value: 'convenience',
  // },
];

const removeSpecialChaRegex = /[\{\}\[\]\/?.,;:|\*~`!^\-_+<>@\#$%&\\\=\\'\"]/gi;

export { ORDER_TYPE, ORDER_TYPE_ALL_ARR, removeSpecialChaRegex };
