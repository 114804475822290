import { useDialogActionContext } from '@locked-dobby/library-react-dialog';
import { detectPlatformIdentity } from 'script/util/platform-utils';
import styled from 'styled-components';

import logo from './img/logo.png';

const GuideInstallAppDialog = () => {
  const { hide } = useDialogActionContext();

  const handleDismiss = () => {
    const currentDate = new Date();
    const dissmissDate = new Date(currentDate);
    dissmissDate.setDate(currentDate.getDate() + 1);
    localStorage.setItem(
      'guide-install-app-at-time',
      dissmissDate.getTime().toString()
    );
  };

  const handleDownloadButton = () => {
    if (detectPlatformIdentity().isAos) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.tpirates.tpiratesapp&hl=ko';
    } else if (detectPlatformIdentity().isIos) {
      window.location.href =
        'https://apps.apple.com/kr/app/%EC%9D%B8%EC%96%B4%EA%B5%90%EC%A3%BC%ED%95%B4%EC%A0%81%EB%8B%A8/id1051182256';
    }
  };

  return (
    <BottomSheetDialogContainerWrapCss>
      <GuideInstallAppDialogStyle>
        <div className="dialogCssDim" />
        <div className="dialogCssContentWrap">
          <div className="dialogCssContentHeaderWrap">
            <div className="dialogCssContentHeaderTitle">
              <img className="logo" />
            </div>
            <p className="dialogCssContentHeaderMessage">
              전국 수산시장의 시세와 할인 혜택을 앱에서 경험해보세요.
            </p>
          </div>
          <div className="dialogCssActionWrap">
            <button
              className="dialogCssActionButton positive"
              onClick={() => {
                handleDownloadButton();
                hide(true);
              }}
            >
              지금 앱 설치하기
            </button>
            <button
              className="dialogCssActionButton negative"
              onClick={() => {
                handleDismiss();
                hide(false);
              }}
            >
              모바일 웹으로 볼래요.
            </button>
          </div>
        </div>
      </GuideInstallAppDialogStyle>
    </BottomSheetDialogContainerWrapCss>
  );
};

export default GuideInstallAppDialog;

const BottomSheetDialogContainerWrapCss = styled.div`
  touch-action: none;
  z-index: 30;
  position: relative;
  top: 0%;
  left: 0;
  height: 100%;
  width: 100%;
`;

const GuideInstallAppDialogStyle = styled.div`
  & > .dialogCssDim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
  }
  & > .dialogCssContentWrap {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    padding: 32px;
    gap: 16px;
    background: #fff;
    bottom: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    & > .dialogCssContentHeaderWrap {
      display: flex;
      gap: 16px;
      & > .dialogCssContentHeaderTitle {
        & > .logo {
          border-radius: 22.5%;
          background-image: url(${logo});
          background-size: contain;
          width: 70px;
          height: 70px;
        }
      }
      & > p {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.3;
        letter-spacing: 0.3px;
        max-width: 360px; // 기획팀장님 요청사항
      }
    }
    & > .dialogCssActionWrap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      & > .dialogCssActionButton {
        width: 100%;
      }
      & > .positive {
        background: #2f80ed;
        color: #fff;
        padding: 8px;
        border-radius: 4px;
      }
      & > .negative {
        background: #fff;
        color: #383b3e;
        padding: 8px;
        border-radius: 4px;
      }
    }
  }
`;
