import { createAsyncThunk } from '@reduxjs/toolkit';
import * as storeAPI from 'api/store';
import dayjs from 'dayjs';

export const getShippingMethod = createAsyncThunk(
  'getShippingMethod',
  async (storeCode, { rejectWithValue }) => {
    try {
      const { data } = await storeAPI.getShippingMethod(storeCode);
      const serviceCount = data.orderTypes.reduce((acc, cur) => {
        return cur.open ? acc + 1 : acc;
      }, 0);

      const now = dayjs();
      const deadline = now
        .clone()
        .set('hour', 12)
        .set('minute', 0)
        .set('second', 0);
      const isAvailable = now.isBefore(deadline);

      return {
        isOnly: serviceCount <= 1,
        storeCode,
        ...data,
        orderTypes: data.orderTypes.map((type) => {
          if (
            type.orderType === 'DAY_DELIVERY'
            // || type.orderType === 'CONVENIENCE_STORE'
          ) {
            return { ...type, isAvailable: type.open ? isAvailable : false };
          }

          return type;
        }),
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

export const getAvailableOrderTypes = createAsyncThunk(
  'getAvailableOrderTypes',
  async (storeCode, { rejectWithValue }) => {
    try {
      const { data } = await storeAPI.getShippingMethod(storeCode);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
