/**
 * 서비스에 특화된 유틸을을 작성합니다!
 */

import {
  DISCOUNT_TYPE_COUPON,
  DISCOUNT_TYPE_MESSAGE_COUPON,
  DISCOUNT_TYPE_MESSAGE_SPECIAL,
  DISCOUNT_TYPE_SPECIAL,
  // ORDER_TYPE_CONVENIENCE_STORE,
  ORDER_TYPE_DAY_DELIVERY,
  // ORDER_TYPE_MESSAGE_CONVENIENCE_STORE,
  ORDER_TYPE_MESSAGE_DAY_DELIVERY,
  ORDER_TYPE_MESSAGE_PACKAGE,
  ORDER_TYPE_MESSAGE_QUICK,
  ORDER_TYPE_PACKAGE,
  ORDER_TYPE_QUICK,
  PRICE_TYPE_EACH,
  PRICE_TYPE_G,
  PRICE_TYPE_KG,
  SALE_STATUS_TYPE_COMING_SOON,
  SALE_STATUS_TYPE_FOR_SALE,
  SALE_STATUS_TYPE_OUT_OF_STOCK,
  SALE_STATUS_TYPE_SOLD_OUT,
} from 'script/const/service-consts';

// TODO hpark 전체 항목에 대해 반영 (일부 항목만 반영됨) + hasToken 등으로 이름을 바꿀지 검토
export const isServiceLoggedIn = () => {
  const loginData = localStorage.getItem('isSignedIn');
  // loginData seller 등 들어 올 수 있음
  return loginData ? loginData !== 'notLogin' || loginData === 'true' : false;
};

export const getPriceUnitName = (unit, defaultValue = '-') => {
  let name = defaultValue;

  switch (unit) {
    case PRICE_TYPE_KG:
      name = 'kg';
      break;
    case PRICE_TYPE_G:
      name = 'g';
      break;
    case PRICE_TYPE_EACH:
      name = '마리';
      break;
  }

  return name;
};

export const getPricePerUnitDisplay = ({ price, unit, unitValue }) => {
  if (!unitValue || !price) {
    return '-';
  }
  const info = getPricePerUnitInfo({ price, unit, unitValue });
  if (info) {
    return `${info.perUnit}당 ${info.perPrice.toLocaleString()}원`;
  }
  return '-';
};

export const getPricePerUnitInfo = ({ price, unit, unitValue }) => {
  let info = undefined;

  switch (unit) {
    case PRICE_TYPE_KG:
      info = { perPrice: Math.round(price / (unitValue / 1)), perUnit: '1kg' };
      break;
    case PRICE_TYPE_G:
      info = {
        perPrice: Math.round(
          price / ((unitValue >= 100 ? unitValue : 100) / 100)
        ),
        perUnit: '100g',
      };
      break;
    case PRICE_TYPE_EACH:
      info = { perPrice: Math.round(price / unitValue), perUnit: '1마리' };
      break;
  }

  return info;
};

// min?: number
export const getProductChoiceDefaultValue = (min) => {
  return min ?? 1;
};

export const getAssortedProductChoiceDefaultValue = (min) => {
  return min ?? 0;
};

export const getAssetUrl = (key) => {
  if (key === undefined) return undefined;
  return `https://public-assets.tpirates.com/contents${key}`;
};

export const getSaleStatusMessage = (saleStatus) => {
  let message;
  if (saleStatus === SALE_STATUS_TYPE_SOLD_OUT) {
    message = '판매 종료';
  } else if (saleStatus === SALE_STATUS_TYPE_OUT_OF_STOCK) {
    message = '품절';
  } else if (saleStatus === SALE_STATUS_TYPE_COMING_SOON) {
    message = '판매 예정';
  } else if (saleStatus === SALE_STATUS_TYPE_FOR_SALE) {
    message = '판매 중';
  } else {
    //
  }
  return message;
};

export const getExclusiveInfoToOrderType = (exclusiveInfo) => {
  const arr = [];
  // if (exclusiveInfo.isConvenienceStorePickup) {
  //   arr.push(ORDER_TYPE_CONVENIENCE_STORE);
  // }
  if (exclusiveInfo.isDayDelivery) {
    arr.push(ORDER_TYPE_DAY_DELIVERY);
  }
  if (exclusiveInfo.isPackage) {
    arr.push(ORDER_TYPE_PACKAGE);
  }
  if (exclusiveInfo.isQuickDelivery) {
    arr.push(ORDER_TYPE_QUICK);
  }
  return arr;
};

export const getExclusiveInfoToOrderTypeMessage = (exclusiveInfo) => {
  const arr = [];
  // if (exclusiveInfo?.isConvenienceStorePickup) {
  //   arr.push(ORDER_TYPE_MESSAGE_CONVENIENCE_STORE);
  // }
  if (exclusiveInfo?.isDayDelivery) {
    arr.push(ORDER_TYPE_MESSAGE_DAY_DELIVERY);
  }
  if (exclusiveInfo?.isPackage) {
    arr.push(ORDER_TYPE_MESSAGE_PACKAGE);
  }
  if (exclusiveInfo?.isQuickDelivery) {
    arr.push(ORDER_TYPE_MESSAGE_QUICK);
  }
  return arr;
};

export const getDiscountChipTitle = (discountType, defaultValue = '-') => {
  let name = defaultValue;

  switch (discountType) {
    case DISCOUNT_TYPE_SPECIAL:
      name = DISCOUNT_TYPE_MESSAGE_SPECIAL;
      break;
    case DISCOUNT_TYPE_COUPON:
      name = DISCOUNT_TYPE_MESSAGE_COUPON;
      break;
  }

  return name;
};

export const getDiscountIconName = (discountType) => {
  switch (discountType) {
    case DISCOUNT_TYPE_SPECIAL:
      return 'superprice_filled-16_1';
    case DISCOUNT_TYPE_COUPON:
      return 'coupon-16_1';
  }
};
