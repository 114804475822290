import { useDialogContext } from '@locked-dobby/library-react-dialog';
import { useCallback, useEffect, useRef } from 'react';
import { detectPlatformIdentity } from 'script/util/platform-utils';

import GuideInstallAppDialog from '../dialog/guide-install-app-dialog';

const GuideInstallAppChecker = ({ children }) => {
  const { showDialog } = useDialogContext();
  const hasChecked = useRef(false); // 중복 호출 방지

  const checkDissmissDate = useCallback(() => {
    if (hasChecked.current) return;
    hasChecked.current = true;

    const platformIdentity = detectPlatformIdentity();
    if (
      platformIdentity.isTpiratesIosApp ||
      platformIdentity.isTpiratesAosApp ||
      platformIdentity.isPc
    )
      return;

    const dismissedDate = localStorage.getItem('guide-install-app-at-time');
    if (dismissedDate) {
      const currentDate = new Date();
      // 2주후...
      const dissmissDate = new Date(Number(dismissedDate));
      if (currentDate > dissmissDate) {
        showDialog(<GuideInstallAppDialog />);
      }
    } else {
      showDialog(<GuideInstallAppDialog />);
    }
  }, [showDialog]);

  useEffect(() => {
    if (hasChecked.current) return;
    checkDissmissDate();
  }, [checkDissmissDate, hasChecked]);

  return <div>{children}</div>;
};

export default GuideInstallAppChecker;
