export const PRICE_TYPE_DEFAULT = null; // default (선택 안함)
export const PRICE_TYPE_G = 'G'; // 그램(g)
export const PRICE_TYPE_KG = 'KG'; // 킬로그램(kg)
export const PRICE_TYPE_EACH = 'EACH'; // 마리

export const SALE_STATUS_TYPE_SOLD_OUT = 'SOLD_OUT'; // 판매 종료
export const SALE_STATUS_TYPE_OUT_OF_STOCK = 'OUT_OF_STOCK'; // 품절
export const SALE_STATUS_TYPE_COMING_SOON = 'COMING_SOON'; // 판매 예정
export const SALE_STATUS_TYPE_FOR_SALE = 'FOR_SALE'; // 판매 중

export const ORDER_TYPE_QUICK = 'QUICK'; // 퀵
export const ORDER_TYPE_PACKAGE = 'PACKAGE'; // 포장
export const ORDER_TYPE_DAY_DELIVERY = 'DAY_DELIVERY'; // 당일 배송
// export const ORDER_TYPE_CONVENIENCE_STORE = 'CONVENIENCE_STORE'; // 편의점 배송

export const ORDER_TYPE_MESSAGE_QUICK = '퀵';
export const ORDER_TYPE_MESSAGE_PACKAGE = '포장';
export const ORDER_TYPE_MESSAGE_DAY_DELIVERY = '당일 배송';
// export const ORDER_TYPE_MESSAGE_CONVENIENCE_STORE = '편의점 배송';

export const DISCOUNT_TYPE_MESSAGE_SPECIAL = '특가할인'; // 특가할인
export const DISCOUNT_TYPE_MESSAGE_COUPON = '쿠폰할인'; // 쿠폰할인

export const DISCOUNT_TYPE_SPECIAL = 'SPECIAL_DISCOUNT'; // 특가할인
export const DISCOUNT_TYPE_COUPON = 'COUPON'; // 쿠폰할인
