export const getOrderStatus = (status, orderType) => {
  // if (orderType === 'CONVENIENCE_STORE') {
  //   switch (status) {
  //     case 'REQUEST':
  //       return '결제 완료';
  //     case 'SENT':
  //       return '픽업 가능';
  //     case 'PURCHASE_CONFIRMATION':
  //       return '수령 확인';
  //   }
  // }

  if (orderType === 'PACKAGE') {
    switch (status) {
      case 'PURCHASE_CONFIRMATION':
        return '픽업 완료';
      case 'REQUEST':
        return '주문 접수 중';
      case 'RECEIVED':
        return '상품 준비중';
      case 'SENDING':
        return '포장 완료';
      case 'SENT':
        return '픽업 완료';
    }
  }

  if (orderType === 'DAY_DELIVERY') {
    switch (status) {
      case 'APPROVAL_PAYMENT':
        return '결제 완료';
      case 'REQUEST':
        return '주문 전달 완료';
      case 'SENDING':
        return '배송 중';
      case 'SENT':
        return '배송 완료';
    }
  }

  switch (status) {
    case 'REQUEST':
      return '주문 접수 중';
    case 'RECEIVED':
      return '상품 준비 중';
    case 'SENDING':
      return '배송 중';
    case 'SENT':
      return '배송 완료';
    case 'BEFORE_PAYMENT':
      return '결제 전';
    default:
      return '주문 취소';
  }
};
