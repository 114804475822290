import address from 'features/addressSlice';
import cart from 'features/cartSlice';
import checkout from 'features/checkoutSlice';
import cu from 'features/cuSlice';
import deliveryTime from 'features/deliveryTimeSlice';
import dialog from 'features/dialogSlice';
import global from 'features/globalSlice';
import orderForm from 'features/orderFormSlice';
import orderStore from 'features/orderStoreSlice';
import profile from 'features/profileSlice';
import shippingMethod from 'features/shippingMethodSlice';
import status from 'features/statusReducer';
import { combineReducers } from 'redux';

import biz_item from './biz/item';
import biz_list from './biz/list';
import biz_order from './biz/order';
import content_list from './content/list';
import content_view from './content/view';
import coupons from './coupons';
import app from './global/app';
import mode from './global/mode';
import session from './global/session';
import history from './history';
import home from './home';
import home_search from './home_search';
import mypage from './mypage';
import orderSuccess from './orderSuccess';
// import price from './price';
import retail from './retail';
import review from './review';
import store_list from './store/list';
import store_view from './store/view';

const APP = 'global.app';
const MODE = 'global.mode';
const SESSION = 'global.session';
const STORE = {
  LIST: 'store.list',
  VIEW: 'store.view',
};
const CONTENT = {
  LIST: 'content.list',
  VIEW: 'content.view',
};
const BIZ = {
  ITEM: 'biz.item',
  LIST: 'biz.list',
  ORDER: 'biz.order',
};

const rootReducer = combineReducers({
  [APP]: app,
  [MODE]: mode,
  [SESSION]: session,
  address,
  cart,
  [CONTENT.LIST]: content_list,
  [CONTENT.VIEW]: content_view,
  [STORE.LIST]: store_list,
  [STORE.VIEW]: store_view,
  checkout,
  coupons,
  [BIZ.LIST]: biz_list,
  [BIZ.ITEM]: biz_item,
  [BIZ.ORDER]: biz_order,
  cu,
  deliveryTime,
  dialog,
  global,
  history,
  home,
  home_search,
  mypage,
  orderForm,
  orderStore,
  orderSuccess,
  // price,
  profile,
  retail,
  review,
  shippingMethod,
  status,
});

export { APP, BIZ, CONTENT, MODE, SESSION, STORE };

export default rootReducer;
